/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './';

/**
 * View-Model: Leistungsnachweis für Projekt
 * @export
 * @interface LeistungsnachweisModel
 */
export interface LeistungsnachweisModel {
    /**
     * ID
     * @type {string}
     * @memberof LeistungsnachweisModel
     */
    id?: string | null;
    /**
     * Projekt-ID
     * @type {string}
     * @memberof LeistungsnachweisModel
     */
    projektID?: string;
    /**
     * Nachweis durchgefuehrt von
     * @type {string}
     * @memberof LeistungsnachweisModel
     */
    durchgefuehrtVon?: string | null;
    /**
     * Text des Nachweises
     * @type {string}
     * @memberof LeistungsnachweisModel
     */
    nachweis?: string | null;
    /**
     * Text der Notizen
     * @type {string}
     * @memberof LeistungsnachweisModel
     */
    notizen?: string | null;
    /**
     * Leistung Zeit (hh:mm)
     * @type {string}
     * @memberof LeistungsnachweisModel
     */
    zeit?: string | null;
    /**
     * Name für Signature
     * @type {string}
     * @memberof LeistungsnachweisModel
     */
    name?: string | null;
    /**
     * Email
     * @type {string}
     * @memberof LeistungsnachweisModel
     */
    kundenMail?: string | null;
    /**
     * Attachements der Leistung
     * @type {Array<Attachment>}
     * @memberof LeistungsnachweisModel
     */
    attachments?: Array<Attachment> | null;
    /**
     * Leistungsnachweis ausgefüllt und gespeichert?
     * @type {boolean}
     * @memberof LeistungsnachweisModel
     */
    canSignature?: boolean;
    /**
     * Signatur vorhanden?
     * @type {boolean}
     * @memberof LeistungsnachweisModel
     */
    hasSignature?: boolean;
    /**
     * Mailservice eingerichtet?
     * @type {boolean}
     * @memberof LeistungsnachweisModel
     */
    canSendMail?: boolean;
}

export function LeistungsnachweisModelFromJSON(json: any): LeistungsnachweisModel {
    return LeistungsnachweisModelFromJSONTyped(json, false);
}

export function LeistungsnachweisModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeistungsnachweisModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projektID': !exists(json, 'projektID') ? undefined : json['projektID'],
        'durchgefuehrtVon': !exists(json, 'durchgefuehrtVon') ? undefined : json['durchgefuehrtVon'],
        'nachweis': !exists(json, 'nachweis') ? undefined : json['nachweis'],
        'notizen': !exists(json, 'notizen') ? undefined : json['notizen'],
        'zeit': !exists(json, 'zeit') ? undefined : json['zeit'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'kundenMail': !exists(json, 'kundenMail') ? undefined : json['kundenMail'],
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] === null ? null : (json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'canSignature': !exists(json, 'canSignature') ? undefined : json['canSignature'],
        'hasSignature': !exists(json, 'hasSignature') ? undefined : json['hasSignature'],
        'canSendMail': !exists(json, 'canSendMail') ? undefined : json['canSendMail'],
    };
}

export function LeistungsnachweisModelToJSON(value?: LeistungsnachweisModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projektID': value.projektID,
        'durchgefuehrtVon': value.durchgefuehrtVon,
        'nachweis': value.nachweis,
        'notizen': value.notizen,
        'zeit': value.zeit,
        'name': value.name,
        'kundenMail': value.kundenMail,
        'attachments': value.attachments === undefined ? undefined : (value.attachments === null ? null : (value.attachments as Array<any>).map(AttachmentToJSON)),
        'canSignature': value.canSignature,
        'hasSignature': value.hasSignature,
        'canSendMail': value.canSendMail,
    };
}


