/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EBeantragbar {
    None = 'None',
    Anfrage = 'Anfrage',
    Genehmigt = 'Genehmigt'
}

export function EBeantragbarFromJSON(json: any): EBeantragbar {
    return EBeantragbarFromJSONTyped(json, false);
}

export function EBeantragbarFromJSONTyped(json: any, ignoreDiscriminator: boolean): EBeantragbar {
    return json as EBeantragbar;
}

export function EBeantragbarToJSON(value?: EBeantragbar | null): any {
    return value as any;
}

