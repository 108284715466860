/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Einteilung,
    EinteilungFromJSON,
    EinteilungFromJSONTyped,
    EinteilungToJSON,
} from './';

/**
 * Info zum Arbeitstag
 * @export
 * @interface TagStempelInfo
 */
export interface TagStempelInfo {
    /**
     * Soll Arbeitszeit eines Mitarbeiters
     * @type {string}
     * @memberof TagStempelInfo
     */
    sollArbeitszeit?: string | null;
    /**
     * Ist Arbeitszeit eines Mitarbeiters
     * @type {string}
     * @memberof TagStempelInfo
     */
    istArbeitsZeit?: string | null;
    /**
     * Sonstige Arbeitszeit eines Mitarbeiters /Arzt, Amtsweg, ...)
     * @type {string}
     * @memberof TagStempelInfo
     */
    sonstigeArbeitsZeit?: string | null;
    /**
     * Tagessaldo eines Mitarbeiters
     * @type {string}
     * @memberof TagStempelInfo
     */
    tagesSaldo?: string | null;
    /**
     * Arbeitszeit von - bis eines Mitarbeiters
     * @type {string}
     * @memberof TagStempelInfo
     */
    normalArbeizszeit?: string | null;
    /**
     * Automatische Pause am Anfang oder Ende abgezogen
     * @type {string}
     * @memberof TagStempelInfo
     */
    ohnePause?: string | null;
    /**
     * Einteilungsinformationen für einen Arbeitstag (Simple View)
     * @type {Array<Einteilung>}
     * @memberof TagStempelInfo
     */
    einteilungen?: Array<Einteilung> | null;
}

export function TagStempelInfoFromJSON(json: any): TagStempelInfo {
    return TagStempelInfoFromJSONTyped(json, false);
}

export function TagStempelInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagStempelInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sollArbeitszeit': !exists(json, 'sollArbeitszeit') ? undefined : json['sollArbeitszeit'],
        'istArbeitsZeit': !exists(json, 'istArbeitsZeit') ? undefined : json['istArbeitsZeit'],
        'sonstigeArbeitsZeit': !exists(json, 'sonstigeArbeitsZeit') ? undefined : json['sonstigeArbeitsZeit'],
        'tagesSaldo': !exists(json, 'tagesSaldo') ? undefined : json['tagesSaldo'],
        'normalArbeizszeit': !exists(json, 'normalArbeizszeit') ? undefined : json['normalArbeizszeit'],
        'ohnePause': !exists(json, 'ohnePause') ? undefined : json['ohnePause'],
        'einteilungen': !exists(json, 'einteilungen') ? undefined : (json['einteilungen'] === null ? null : (json['einteilungen'] as Array<any>).map(EinteilungFromJSON)),
    };
}

export function TagStempelInfoToJSON(value?: TagStempelInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sollArbeitszeit': value.sollArbeitszeit,
        'istArbeitsZeit': value.istArbeitsZeit,
        'sonstigeArbeitsZeit': value.sonstigeArbeitsZeit,
        'tagesSaldo': value.tagesSaldo,
        'normalArbeizszeit': value.normalArbeizszeit,
        'ohnePause': value.ohnePause,
        'einteilungen': value.einteilungen === undefined ? undefined : (value.einteilungen === null ? null : (value.einteilungen as Array<any>).map(EinteilungToJSON)),
    };
}


