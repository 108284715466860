/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EAZTyp,
    EAZTypFromJSON,
    EAZTypFromJSONTyped,
    EAZTypToJSON,
} from './';

/**
 * Model: Zeitstempel
 * @export
 * @interface ZeitstempelModel
 */
export interface ZeitstempelModel {
    /**
     * ID (Scan-Zeitpunkt) des Zeitstempels
     * @type {Date}
     * @memberof ZeitstempelModel
     */
    id?: Date | null;
    /**
     * Ticks (Scan-Zeitpunkt) des Zeitstempels
     * @type {string}
     * @memberof ZeitstempelModel
     */
    ticks?: string | null;
    /**
     * Display-Zeit mit Datum
     * @type {Date}
     * @memberof ZeitstempelModel
     */
    stempel?: Date | null;
    /**
     * Zusätztliche Info zum Zeitstempel
     * @type {string}
     * @memberof ZeitstempelModel
     */
    info?: string | null;
    /**
     * 
     * @type {EAZTyp}
     * @memberof ZeitstempelModel
     */
    typ?: EAZTyp;
    /**
     * Fehler beim Zeitstempel?
     * @type {string}
     * @memberof ZeitstempelModel
     */
    fehler?: string | null;
    /**
     * GPS: Latitude des Zeitstempels
     * @type {number}
     * @memberof ZeitstempelModel
     */
    latitude?: number | null;
    /**
     * GPS:Longitude des Zeitstempels
     * @type {number}
     * @memberof ZeitstempelModel
     */
    longitude?: number | null;
}

export function ZeitstempelModelFromJSON(json: any): ZeitstempelModel {
    return ZeitstempelModelFromJSONTyped(json, false);
}

export function ZeitstempelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZeitstempelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : (json['id'] === null ? null : new Date(json['id'])),
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'stempel': !exists(json, 'stempel') ? undefined : (json['stempel'] === null ? null : new Date(json['stempel'])),
        'info': !exists(json, 'info') ? undefined : json['info'],
        'typ': !exists(json, 'typ') ? undefined : EAZTypFromJSON(json['typ']),
        'fehler': !exists(json, 'fehler') ? undefined : json['fehler'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function ZeitstempelModelToJSON(value?: ZeitstempelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id === undefined ? undefined : (value.id === null ? null : value.id.toISOString()),
        'ticks': value.ticks,
        'stempel': value.stempel === undefined ? undefined : (value.stempel === null ? null : value.stempel.toISOString()),
        'info': value.info,
        'typ': EAZTypToJSON(value.typ),
        'fehler': value.fehler,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}


