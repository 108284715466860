/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EBeantragbar,
    EBeantragbarFromJSON,
    EBeantragbarFromJSONTyped,
    EBeantragbarToJSON,
    ELookup,
    ELookupFromJSON,
    ELookupFromJSONTyped,
    ELookupToJSON,
    ValidationResult,
    ValidationResultFromJSON,
    ValidationResultFromJSONTyped,
    ValidationResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface Tagesstempel
 */
export interface Tagesstempel {
    /**
     * 
     * @type {boolean}
     * @memberof Tagesstempel
     */
    noValidate?: boolean;
    /**
     * 
     * @type {Array<ValidationResult>}
     * @memberof Tagesstempel
     */
    readonly errors?: Array<ValidationResult> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Tagesstempel
     */
    readonly hasErrors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Tagesstempel
     */
    readonly isValid?: boolean;
    /**
     * 
     * @type {ELookup}
     * @memberof Tagesstempel
     */
    id?: ELookup;
    /**
     * 
     * @type {string}
     * @memberof Tagesstempel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tagesstempel
     */
    beschreibung?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tagesstempel
     */
    fileID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tagesstempel
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tagesstempel
     */
    fileContentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tagesstempel
     */
    fileContent?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Tagesstempel
     */
    isZulage?: boolean;
    /**
     * 
     * @type {EBeantragbar}
     * @memberof Tagesstempel
     */
    beantragbar?: EBeantragbar;
    /**
     * 
     * @type {boolean}
     * @memberof Tagesstempel
     */
    beantragungFreigeben?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Tagesstempel
     */
    beantragungAnfrage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Tagesstempel
     */
    isEingeschaltet?: boolean;
}

export function TagesstempelFromJSON(json: any): Tagesstempel {
    return TagesstempelFromJSONTyped(json, false);
}

export function TagesstempelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tagesstempel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'noValidate': !exists(json, 'noValidate') ? undefined : json['noValidate'],
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : (json['errors'] as Array<any>).map(ValidationResultFromJSON)),
        'hasErrors': !exists(json, 'hasErrors') ? undefined : json['hasErrors'],
        'isValid': !exists(json, 'isValid') ? undefined : json['isValid'],
        'id': !exists(json, 'id') ? undefined : ELookupFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'beschreibung': !exists(json, 'beschreibung') ? undefined : json['beschreibung'],
        'fileID': !exists(json, 'fileID') ? undefined : json['fileID'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'fileContentType': !exists(json, 'fileContentType') ? undefined : json['fileContentType'],
        'fileContent': !exists(json, 'fileContent') ? undefined : json['fileContent'],
        'isZulage': !exists(json, 'isZulage') ? undefined : json['isZulage'],
        'beantragbar': !exists(json, 'beantragbar') ? undefined : EBeantragbarFromJSON(json['beantragbar']),
        'beantragungFreigeben': !exists(json, 'beantragungFreigeben') ? undefined : json['beantragungFreigeben'],
        'beantragungAnfrage': !exists(json, 'beantragungAnfrage') ? undefined : json['beantragungAnfrage'],
        'isEingeschaltet': !exists(json, 'isEingeschaltet') ? undefined : json['isEingeschaltet'],
    };
}

export function TagesstempelToJSON(value?: Tagesstempel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'noValidate': value.noValidate,
        'id': ELookupToJSON(value.id),
        'name': value.name,
        'beschreibung': value.beschreibung,
        'fileID': value.fileID,
        'fileName': value.fileName,
        'fileContentType': value.fileContentType,
        'fileContent': value.fileContent,
        'isZulage': value.isZulage,
        'beantragbar': EBeantragbarToJSON(value.beantragbar),
        'beantragungFreigeben': value.beantragungFreigeben,
        'beantragungAnfrage': value.beantragungAnfrage,
        'isEingeschaltet': value.isEingeschaltet,
    };
}


