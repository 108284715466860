/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjektKontierungModified,
    ProjektKontierungModifiedFromJSON,
    ProjektKontierungModifiedFromJSONTyped,
    ProjektKontierungModifiedToJSON,
    Tagesstempel,
    TagesstempelFromJSON,
    TagesstempelFromJSONTyped,
    TagesstempelToJSON,
    ZeitstempelRow,
    ZeitstempelRowFromJSON,
    ZeitstempelRowFromJSONTyped,
    ZeitstempelRowToJSON,
} from './';

/**
 * Model für die Zeiterfassung (Zulagen, Zeiten, Tagesstempel)
 * @export
 * @interface StempelModel
 */
export interface StempelModel {
    /**
     * Soll von lt. Arbeitszeitmodel
     * @type {Date}
     * @memberof StempelModel
     */
    sollVon?: Date | null;
    /**
     * Soll bis lt. Arbeitszeitmodel
     * @type {Date}
     * @memberof StempelModel
     */
    sollBis?: Date | null;
    /**
     * Optionen für Tagesstempel
     * @type {Array<Tagesstempel>}
     * @memberof StempelModel
     */
    tagesStempelAuswahl?: Array<Tagesstempel> | null;
    /**
     * Gewählte Tagesstempel
     * @type {Array<Tagesstempel>}
     * @memberof StempelModel
     */
    stempelAktiv?: Array<Tagesstempel> | null;
    /**
     * Liste der Zeitstempel als Paar
     * @type {Array<ZeitstempelRow>}
     * @memberof StempelModel
     */
    stempelRow?: Array<ZeitstempelRow> | null;
    /**
     * Liste der Montagestempel als Paar
     * @type {Array<ZeitstempelRow>}
     * @memberof StempelModel
     */
    montageRow?: Array<ZeitstempelRow> | null;
    /**
     * Kontierungen zum Arbeitstag
     * @type {Array<ProjektKontierungModified>}
     * @memberof StempelModel
     */
    projektKontierungen?: Array<ProjektKontierungModified> | null;
    /**
     * Zeitstempelrows benutzt
     * @type {number}
     * @memberof StempelModel
     */
    rowsUsed?: number;
    /**
     * GPS: Latitude des Zeitstempels
     * @type {number}
     * @memberof StempelModel
     */
    latitude?: number | null;
    /**
     * GPS:Longitude des Zeitstempels
     * @type {number}
     * @memberof StempelModel
     */
    longitude?: number | null;
}

export function StempelModelFromJSON(json: any): StempelModel {
    return StempelModelFromJSONTyped(json, false);
}

export function StempelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StempelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sollVon': !exists(json, 'sollVon') ? undefined : (json['sollVon'] === null ? null : new Date(json['sollVon'])),
        'sollBis': !exists(json, 'sollBis') ? undefined : (json['sollBis'] === null ? null : new Date(json['sollBis'])),
        'tagesStempelAuswahl': !exists(json, 'tagesStempelAuswahl') ? undefined : (json['tagesStempelAuswahl'] === null ? null : (json['tagesStempelAuswahl'] as Array<any>).map(TagesstempelFromJSON)),
        'stempelAktiv': !exists(json, 'stempelAktiv') ? undefined : (json['stempelAktiv'] === null ? null : (json['stempelAktiv'] as Array<any>).map(TagesstempelFromJSON)),
        'stempelRow': !exists(json, 'stempelRow') ? undefined : (json['stempelRow'] === null ? null : (json['stempelRow'] as Array<any>).map(ZeitstempelRowFromJSON)),
        'montageRow': !exists(json, 'montageRow') ? undefined : (json['montageRow'] === null ? null : (json['montageRow'] as Array<any>).map(ZeitstempelRowFromJSON)),
        'projektKontierungen': !exists(json, 'projektKontierungen') ? undefined : (json['projektKontierungen'] === null ? null : (json['projektKontierungen'] as Array<any>).map(ProjektKontierungModifiedFromJSON)),
        'rowsUsed': !exists(json, 'rowsUsed') ? undefined : json['rowsUsed'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function StempelModelToJSON(value?: StempelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sollVon': value.sollVon === undefined ? undefined : (value.sollVon === null ? null : value.sollVon.toISOString()),
        'sollBis': value.sollBis === undefined ? undefined : (value.sollBis === null ? null : value.sollBis.toISOString()),
        'tagesStempelAuswahl': value.tagesStempelAuswahl === undefined ? undefined : (value.tagesStempelAuswahl === null ? null : (value.tagesStempelAuswahl as Array<any>).map(TagesstempelToJSON)),
        'stempelAktiv': value.stempelAktiv === undefined ? undefined : (value.stempelAktiv === null ? null : (value.stempelAktiv as Array<any>).map(TagesstempelToJSON)),
        'stempelRow': value.stempelRow === undefined ? undefined : (value.stempelRow === null ? null : (value.stempelRow as Array<any>).map(ZeitstempelRowToJSON)),
        'montageRow': value.montageRow === undefined ? undefined : (value.montageRow === null ? null : (value.montageRow as Array<any>).map(ZeitstempelRowToJSON)),
        'projektKontierungen': value.projektKontierungen === undefined ? undefined : (value.projektKontierungen === null ? null : (value.projektKontierungen as Array<any>).map(ProjektKontierungModifiedToJSON)),
        'rowsUsed': value.rowsUsed,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}


