import { EOptions } from "../generated"
import CommonApi from "../api/CommonApi";
import { useQuery } from "react-query";

interface Props {
    codes?: string[]
    codesMitPaket?: CodesMitZusatzpaket[]
    colors?: LegendColor[]
    images?: LegendImages[]
}

export type CodesMitZusatzpaket = {
    codes: string[]
    zusatzpaket: EOptions
}
type LegendColor = {
    css: string 
    content: string 
}
export type LegendImages = {
    backgroundImage: string
    content: string 
}


/**
 * Displays a legend as a grid with a heading on top.  
 * @param codes - Array of strings 
 * @param codesMitPaket - Array of objects of type `{codes: string[], zusatzpaket: EOptions}`. The list of strings is displayed only when the provided package is available. 
 * @param colors - Array of `{css: css-class, content: string}`. Each element will produce a div with the provided css-class and one displaying the provided string. 
 * @param images - Array of `{backgroundImage: string (base64), content:string}`. The image has to be a valid base64-string in the format `url(data:FILE_CONTENT_TYPE;base64,FILE_CONTENT})`.
 */
export const Legende = ({codes, codesMitPaket, colors, images}:Props) => {
    const commonVersions = useQuery(["getVersionGET"], () =>
        CommonApi.getVersionGET({ clearCache: false })
    );

    return ( 
        <>
            <h3 style={{ textAlign: 'center' }}>Legende</h3>
            <div className="legend-grid">
                {codes && codes.map((code, i) => <div key={`code${i}`}>{code}</div>)}
                {
                    codesMitPaket && codesMitPaket.map((codeMitPaket, i) => {
                        return commonVersions?.data?.options?.includes(codeMitPaket.zusatzpaket) && codeMitPaket.codes.map((code, i2) => <div key={`code${i}${i2}`}>{code}</div>)
                    })
                }
                
                {colors && <div className="new-row" /> }
                {
                    colors && colors.map((color,i) => <div className="legend-bg" key={`color${i}`}>
                        <div className={color.css}></div>
                        <div>{color.content}</div>
                    </div>)
                }
                {images && <div className="new-row" />}
                {
                    images && images.map((image, i) => <div className="legend-bg" key={`image${i}`}>
                        <div className="img-container" style={{ backgroundImage: image.backgroundImage}} />
                        <div>{image.content}</div>
                    </div>)
                }
            </div>
        </>
    )
}