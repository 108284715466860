import * as React from 'react';
import { useQuery } from 'react-query';
import MitarbeiterApi from '../api/MitarbeiterApi';
import { Segment, Table } from 'semantic-ui-react';
import { AbwesenheitsModel } from '../generated/models/AbwesenheitsModel';
import { MonthPicker } from './MonthPicker';
import useDocumentTitle, { DOCUMENT_TITLES } from '../hooks/useDocumentTitle';
import { LegendImages, Legende } from './Legende';


interface AbwesenheitsuebersichtProps {
}

export const Abwesenheitsuebersicht: React.FC<AbwesenheitsuebersichtProps> = (props) => {
  useDocumentTitle(DOCUMENT_TITLES.abwesenheitsuebersicht)
  const startFilter = new Date();
  
  const [monatsFilter, setMonatsFilter] = React.useState((startFilter.getMonth() + 1)); // Jänner Monat wäre 0 etc
  const [jahresFilter, setJahresFilter] = React.useState(startFilter.getFullYear());
  const [showAll, setShowAll] = React.useState(false)

  React.useEffect(() => {
    if (localStorage && typeof localStorage.getItem('abwesenheiten-showAll') === "string") {
      setShowAll(JSON.parse(localStorage.getItem('abwesenheiten-showAll') as string))
    }
  }, [setShowAll])


  React.useEffect(() => {
    localStorage.setItem('abwesenheiten-showAll', showAll ? "true" : "false")
  }, [showAll])

  const abwesenheitsUebersicht = useQuery(["getAbwesenheitsuebersichtGET", jahresFilter, monatsFilter, showAll], () => {
    return MitarbeiterApi.getAbwesenheitsuebersichtGET({ jahr: jahresFilter, monat: monatsFilter, showAll: showAll })
  });


  const filterOptions = [
    { key: 1, text: 'Jänner', value: 1 },
    { key: 2, text: 'Februar', value: 2 },
    { key: 3, text: 'März', value: 3 },
    { key: 4, text: 'April', value: 4 },
    { key: 5, text: 'Mai', value: 5 },
    { key: 6, text: 'Juni', value: 6 },
    { key: 7, text: 'Juli', value: 7 },
    { key: 8, text: 'August', value: 8 },
    { key: 9, text: 'September', value: 9 },
    { key: 10, text: 'Oktober', value: 10 },
    { key: 11, text: 'November', value: 11 },
    { key: 12, text: 'Dezember', value: 12 }
  ];
  let monat = filterOptions.find(f => f.value === monatsFilter)?.text ?? monatsFilter

  const legendImages:LegendImages[]|undefined = React.useMemo(() => {
    return abwesenheitsUebersicht.data?.tagesstempel?.map((stempel, i) => {
      return {
        backgroundImage: `url(data:${stempel.fileContentType};base64,${stempel.fileContent})`,
        content: stempel.name??"Tagesstempel"
      }
    }) 
  }, [abwesenheitsUebersicht.data?.tagesstempel])

  return (
    <>
      <MonthPicker 
        isLoading={abwesenheitsUebersicht.isLoading}
        monatsFilter={monatsFilter}
        setMonatsFilter={setMonatsFilter}
        jahresFilter={jahresFilter}
        setJahresFilter={setJahresFilter}
        showFilter={!abwesenheitsUebersicht.isLoading && abwesenheitsUebersicht.data?.showAll}
        showAll={showAll}
        setShowAll={ setShowAll}
        title="Abwesenheitsübersicht">
      </MonthPicker>
      {!abwesenheitsUebersicht.isLoading && abwesenheitsUebersicht.data?.abwesenheiten?.length && abwesenheitsUebersicht.data?.abwesenheiten?.length > 0 ?
        <>
          <div className="container_table">
            <Table className="abwesenheitenTabelle" unstackable celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nachname</Table.HeaderCell>
                  <Table.HeaderCell>Vorname</Table.HeaderCell>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  {abwesenheitsUebersicht.data.tage && abwesenheitsUebersicht.data.tage.map(tag => 
                    <Table.HeaderCell className={Number(tag) === startFilter.getDate() && monatsFilter === startFilter.getMonth() + 1 && jahresFilter === startFilter.getFullYear() ? "currentDay" : ""}>{tag}</Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {abwesenheitsUebersicht.data.abwesenheiten.map((abwesenheit: AbwesenheitsModel) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{abwesenheit.nachname}</Table.Cell>
                      <Table.Cell>{abwesenheit.vorname}</Table.Cell>
                      <Table.Cell>{abwesenheit.personalNr}</Table.Cell>
                      {abwesenheit.codes && abwesenheit.codes.map((code, i) => {
                        const numTagesstempel = abwesenheit.tagesstempel?.[i].length??0
                        return <Table.Cell className={code ? code : "dummy"} style={numTagesstempel > 0 ? {height:`max(42px, ${numTagesstempel*21}px)`}:{}}>
                          {numTagesstempel > 0 && abwesenheit.tagesstempel?.[i] && 
                              <div className="tagesstempel-container"> 
                                {abwesenheit.tagesstempel[i].map(stempelID => {
                                  const stempel = abwesenheitsUebersicht.data?.tagesstempel?.find(t => t.id===stempelID)
                                  return stempel && <img className="tagesstempel-img" src={`data:${stempel?.fileContentType};base64,${stempel?.fileContent}`} alt={stempel?.name??"Tagesstempel"} />})} 
                              </div>
                            } 
                          </Table.Cell>})}
                    </Table.Row>
                  )
                }
                )}
              </Table.Body>
              </Table>
            </div> 
          <Segment>
            <Legende 
              codes={["# - Personalnummer"]}
              colors={[
                { css: "legend-box abwesend", content:"Genehmigte Abwesenheiten"},
                { css: "legend-box abwesend-offen", content:"Offene Abwesenheiten"},
                { css: "legend-box berufsschule", content:"Berufsschule / Karenz"},
              ]}
              images={legendImages}
            />
          </Segment>
        </>
        : <Segment hidden={abwesenheitsUebersicht.isLoading} > <h3>Noch keine Abwesenheiten für {monat} {jahresFilter} vorhanden.</h3> </Segment>
      }</>
    
  )
}