/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EAZTyp,
    EAZTypFromJSON,
    EAZTypFromJSONTyped,
    EAZTypToJSON,
    ELookup,
    ELookupFromJSON,
    ELookupFromJSONTyped,
    ELookupToJSON,
    EUrlaubsantragStatus,
    EUrlaubsantragStatusFromJSON,
    EUrlaubsantragStatusFromJSONTyped,
    EUrlaubsantragStatusToJSON,
} from './';

/**
 * Model: Urlaubsanfrage
 * @export
 * @interface UrlaubsanfrageModel
 */
export interface UrlaubsanfrageModel {
    /**
     * ID
     * @type {string}
     * @memberof UrlaubsanfrageModel
     */
    id?: string;
    /**
     * ID des Mitarbeiter
     * @type {string}
     * @memberof UrlaubsanfrageModel
     */
    mitarbeiterID?: string;
    /**
     * 
     * @type {EAZTyp}
     * @memberof UrlaubsanfrageModel
     */
    typ?: EAZTyp;
    /**
     * Tagesstempel Bezeichnung
     * @type {string}
     * @memberof UrlaubsanfrageModel
     */
    tagesstempelName?: string | null;
    /**
     * Von
     * @type {Date}
     * @memberof UrlaubsanfrageModel
     */
    von?: Date;
    /**
     * Bis
     * @type {Date}
     * @memberof UrlaubsanfrageModel
     */
    bis?: Date;
    /**
     * 
     * @type {EUrlaubsantragStatus}
     * @memberof UrlaubsanfrageModel
     */
    status?: EUrlaubsantragStatus;
    /**
     * Notiz zum Status
     * @type {string}
     * @memberof UrlaubsanfrageModel
     */
    notizen?: string | null;
    /**
     * 
     * @type {ELookup}
     * @memberof UrlaubsanfrageModel
     */
    tagesstempelAnfrage?: ELookup;
}

export function UrlaubsanfrageModelFromJSON(json: any): UrlaubsanfrageModel {
    return UrlaubsanfrageModelFromJSONTyped(json, false);
}

export function UrlaubsanfrageModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrlaubsanfrageModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mitarbeiterID': !exists(json, 'mitarbeiterID') ? undefined : json['mitarbeiterID'],
        'typ': !exists(json, 'typ') ? undefined : EAZTypFromJSON(json['typ']),
        'tagesstempelName': !exists(json, 'tagesstempelName') ? undefined : json['tagesstempelName'],
        'von': !exists(json, 'von') ? undefined : (new Date(json['von'])),
        'bis': !exists(json, 'bis') ? undefined : (new Date(json['bis'])),
        'status': !exists(json, 'status') ? undefined : EUrlaubsantragStatusFromJSON(json['status']),
        'notizen': !exists(json, 'notizen') ? undefined : json['notizen'],
        'tagesstempelAnfrage': !exists(json, 'tagesstempelAnfrage') ? undefined : ELookupFromJSON(json['tagesstempelAnfrage']),
    };
}

export function UrlaubsanfrageModelToJSON(value?: UrlaubsanfrageModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mitarbeiterID': value.mitarbeiterID,
        'typ': EAZTypToJSON(value.typ),
        'tagesstempelName': value.tagesstempelName,
        'von': value.von === undefined ? undefined : (value.von.toISOString()),
        'bis': value.bis === undefined ? undefined : (value.bis.toISOString()),
        'status': EUrlaubsantragStatusToJSON(value.status),
        'notizen': value.notizen,
        'tagesstempelAnfrage': ELookupToJSON(value.tagesstempelAnfrage),
    };
}


